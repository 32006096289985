import React, {useEffect, useState} from 'react';
import Pager from './Pager';
import './styles.css'

function MessageBoard() {
    const [messages, setMessages] = useState([]);
    const [name, setName] = useState('');
    const [content, setContent] = useState('');
    const [file, setFile] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [activeTab, setActiveTab] = useState('message');
    const [uploadMessage, setUploadMessage] = useState(''); // State for upload success message

    useEffect(() => {
        fetch(`/api/messages?page=${currentPage}`)
            .then(response => response.json())
            .then(data => {
                if (data?.messages) {
                    setMessages(data.messages);
                }
                setName(data.username);
                setTotalPages(data.totalPages);
            }, error => {
                console.error('An error occurred', error);
            });
    }, [currentPage]);

    const handleSubmit = (e) => {
        e.preventDefault();
        fetch('/api/submit', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({name, content}),
        })
            .then(response => response.json())
            .then(data => {
                if (data?.messages) {
                    setMessages(data.messages);
                }
                setTotalPages(data.totalPages);
                setContent('')
            });
    };

    const handleUpload = (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('file', file);
        fetch('/api/upload', {
            method: 'POST',
            body: formData,
        })
            .then(response => response.json())
            .then(data => {
                if (data?.messages) {
                    setMessages(data.messages);
                }
                setTotalPages(data.totalPages);
                setFile(null)
                document.getElementById('file').value = '';
                setUploadMessage('Upload successful!'); // Set success message
            });
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    return (
        <div className="container mx-auto p-4">
            <h1 className="text-3xl font-bold mb-4">Message Board 1.0.5</h1>
            <div className="tabs mb-4">
                <button
                    className={`tab ${activeTab === 'message' ? 'active' : ''}`}
                    onClick={() => setActiveTab('message')}
                >
                    Post Message
                </button>
                <button
                    className={`tab ${activeTab === 'upload' ? 'active' : ''}`}
                    onClick={() => setActiveTab('upload')}
                >
                    Upload Image
                </button>
            </div>
            {activeTab === 'message' && (
                <form onSubmit={handleSubmit} className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
                    <div className="mb-4">
                        <label htmlFor="name" className="block text-gray-700 text-sm font-bold mb-2">Name:</label>
                        <input
                            type="text"
                            id="name"
                            name="name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            required
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        />
                    </div>
                    <div className="mb-4">
                        <label htmlFor="content" className="block text-gray-700 text-sm font-bold mb-2">Message:</label>
                        <textarea
                            id="content"
                            name="content"
                            value={content}
                            onChange={(e) => setContent(e.target.value)}
                            required
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        />
                    </div>
                    <div className="flex items-center justify-between">
                        <button
                            type="submit"
                            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                        >
                            Submit
                        </button>
                    </div>
                </form>
            )}
            {activeTab === 'upload' && (
                <form onSubmit={handleUpload} className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
                    <div className="mb-4">
                        <label htmlFor="file" className="block text-gray-700 text-sm font-bold mb-2">Upload
                            File:</label>
                        <input
                            type="file"
                            id="file"
                            name="file"
                            onChange={(e) => setFile(e.target.files[0])}
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        />
                    </div>
                    <div className="flex items-center justify-between">
                        <button
                            type="submit"
                            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                        >
                            Upload
                        </button>
                    </div>
                    {uploadMessage && <p className="text-green-500 mt-2">{uploadMessage}</p>}
                </form>
            )}
            <div className="messages bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
                <h2 className="text-2xl font-bold mb-4">Messages</h2>
                {messages.map((msg, index) => (
                    <div className="message border-b border-gray-200 py-2" key={index}>
                        <p className="text-gray-700"><strong>{msg.name}:</strong> {msg.content}</p>
                    </div>
                ))}
            </div>
            <Pager currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange}/>
        </div>
    );
}

export default MessageBoard;